import { useRenderOnMount } from '@kaliber/use-render-on-mount'
import { useMediaQuery } from '@kaliber/use-media-query'

import { useKeenSliderRefs } from '/machinery/useKeenSliderRefs'

import { DocumentCardLightBlue } from '/features/pageOnly/LifeAtKLM/DocumentCard'
import { SliderButtons } from '/features/buildingBlocks/SliderButtons'

import 'keen-slider/keen-slider.min.css'
import mediaStyles from '/cssGlobal/media.css'
import styles from './ContentCards.css'

export function ContentCards({ items, sliderDisabled = false, layoutClassName = undefined }) {
  const isMounted = useRenderOnMount()
  const isViewportMd = useMediaQuery(mediaStyles.viewportMd) ?? false
  const isViewportLg = useMediaQuery(mediaStyles.viewportLg) ?? false

  const initialSlide = 0
  const loop = false
  const slidesPerView = isViewportLg ? 3 : isViewportMd ? 2 : 1
  const [currentSlide, setCurrentSlide] = React.useState(initialSlide)

  const { sliderRef, instanceRef } = useKeenSliderRefs({
    handleSlideChange,
    slidesNum: items.length,
    slidesPerView,
    spacing: isViewportMd ? 32 : 16,
    initialSlide,
    sliderDisabled,
    origin: 0,
    loop,
    interactionTitle: 'content-slider'
  })

  return (
    <section data-x='content-cards' className={cx(styles.component, layoutClassName)}>
      <div ref={sliderRef} className={cx(styles.slider, sliderDisabled && styles.sliderDisabled, 'keen-slider')}>
        {Boolean(items.length) && items.map(item => (
          <div key={item._key} className={cx(styles.slideContainer, 'keen-slider__slide')}>
            <DocumentCardLightBlue layoutClassName={styles.cardLayout} {...{ item }} />
          </div>
        ))}
      </div>
      {isMounted && !sliderDisabled && <SliderButtons maxSlides={items.length} {...{ slidesPerView, currentSlide, loop, handleClick }} />}
    </section>
  )

  function handleClick(idx) {
    instanceRef.current?.moveToIdx(idx)
  }

  function handleSlideChange(idx) {
    setCurrentSlide(idx)
  }
}
